
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import moment from "moment";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";

interface Course {
  id: string;
  createdAt: string;
}

export default defineComponent({
  name: "month-courses",
  props: {
    title: String,
    description: String,
    color: String,
    height: Number,
    courses: Array,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const height = ref(props.height);
    const trimester = JwtService.getTrimester();

    const color = ref(props.color);

    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    const courses: Course[] = props.courses as Course[];

    const maxDate = moment.max(
      courses.map((course) => moment(course.createdAt))
    );

    const minDate = moment.min(
      courses.map((course) => moment(course.createdAt))
    );

    const days: string[] = [];
    const courseSerie: number[] = [];

    let currDate = minDate.startOf("day");
    let lastDate = maxDate.startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      days.push(currDate.format("DD MMMM"));
      courseSerie.push(
        courses.filter(
          (course: Course) =>
            new Date(course.createdAt).getDate() == currDate.date() &&
            new Date(course.createdAt).getMonth() == currDate.month()
        ).length
      );
    }

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: height.value,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: days,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: "#E4E6EF",
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...courseSerie),
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " " + t("course.courses");
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          stops: [0, 100],
        },
      },
      colors: [baseColor],
      markers: {
        colors: [baseColor],
        strokeColor: [lightColor],
        strokeWidth: 3,
      },
    };

    const series = [
      {
        name: t("course.added"),
        data: courseSerie,
      },
    ];

    return {
      chartOptions,
      series,
      t,
      trimester,
    };
  },
});
